.wrapper {
  /*TODO: background-image: url("../public/assets/main_background.png");*/
  /*background-image: url("../public/assets/background.svg");*/
  /*background-size: 100%;*/
  background-color: #1e2128;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  /*background-attachment: fixed;*/
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  0% {
    -webkit-transform: scale(1) rotate(360deg);
    -o-transform: scale(1) rotate(360deg);
    transform: scale(1) rotate(360deg);
    opacity: 0.2;
    border-radius: 0;

  }
  50% {
    -webkit-transform: scale(1.5) rotate(180deg);
    -o-transform: scale(1.5) rotate(180deg);
    transform: scale(1.5) rotate(180deg);
    opacity: 1;
    border-radius: 1rem;

  }
  100% {
    -webkit-transform: scale(1) rotate(0deg);
    -o-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
    opacity: 0.2;
    border-radius: 0;

  }
}
@keyframes rotating {
  0% {
    -ms-transform: scale(1) rotate(360deg);
    -moz-transform: scale(1) rotate(360deg);
    -webkit-transform: scale(1) rotate(360deg);
    -o-transform: scale(1) rotate(360deg);
    transform: scale(1) rotate(360deg);
    opacity: 0.2;
    border-radius: 0;

  }
  50% {
    -ms-transform: scale(1.5) rotate(180deg);
    -moz-transform: scale(1.5) rotate(180deg);
    -webkit-transform: scale(1.5) rotate(180deg);
    -o-transform: scale(1.5) rotate(180deg);
    transform: scale(1.5) rotate(180deg);
    opacity: 1;
    border-radius: 1rem;

  }
  100% {
    -ms-transform: scale(1) rotate(0deg);
    -moz-transform: scale(1) rotate(0deg);
    -webkit-transform: scale(1) rotate(0deg);
    -o-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
    opacity: 0.2;
    border-radius: 0;

  }
}

@-webkit-keyframes scaling /* Safari and Chrome */ {
  0% {
    -webkit-transform: scale(1) rotate(0deg);
    -o-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
    opacity: 0.2;
    border-radius: 0;

  }
  50% {
    -webkit-transform: scale(3) rotate(180deg);
    -o-transform: scale(3) rotate(180deg);
    transform: scale(3) rotate(180deg);
    opacity: 1;
    border-radius: 1rem;

  }
  100% {
    -webkit-transform: scale(1) rotate(360deg);
    -o-transform: scale(1) rotate(360deg);
    transform: scale(1) rotate(360deg);
    opacity: 0.2;
    border-radius: 0;

  }
}
@keyframes scaling {
  0% {
    -ms-transform: scale(1) rotate(0deg);
    -moz-transform: scale(1) rotate(0deg);
    -webkit-transform: scale(1) rotate(0deg);
    -o-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
    opacity: 0.2;
    border-radius: 0;

  }
  50% {
    -ms-transform: scale(3) rotate(180deg);
    -moz-transform: scale(3) rotate(180deg);
    -webkit-transform: scale(3) rotate(180deg);
    -o-transform: scale(3) rotate(180deg);
    transform: scale(3) rotate(180deg);
    opacity: 1;
    border-radius: 1rem;

  }
  100% {
    -ms-transform: scale(1) rotate(360deg);
    -moz-transform: scale(1) rotate(360deg);
    -webkit-transform: scale(1) rotate(360deg);
    -o-transform: scale(1) rotate(360deg);
    transform: scale(1) rotate(360deg);
    opacity: 0.2;
    border-radius: 0;

  }
}

.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.scaling{
  -webkit-animation: scaling 2s ease-in-out infinite;
  -moz-animation: scaling 2s ease-in-out infinite;
  -ms-animation: scaling 2s ease-in-out infinite;
  -o-animation: scaling 2s ease-in-out infinite;
  animation: scaling 2s ease-in-out infinite;
}

.star1{
  position: fixed;
  width: 1.5px;
  height: 1.5px;
  transform: rotate(45deg);
}

.star2{
  position: absolute;
  width: 2px;
  height: 2px;
  transform: rotate(45deg);
}

.star3{
  position: absolute;
  width: 3px;
  height: 3px;
  transform: rotate(45deg);
}

.star4{
  position: absolute;
  width: 4px;
  height: 4px;
  transform: rotate(45deg);
}
.star5{
  position: absolute;
  width: 5px;
  height: 5px;
  transform: rotate(45deg);
}



.wrapperContainer{
  background: linear-gradient(0deg, rgba(30, 33, 39, 0.4), rgba(30, 33, 39, 0.4)), linear-gradient(0deg, #1E2128 2.8%, rgba(30, 33, 40, 0) 80.95%), linear-gradient(180deg, #1E2128 1.91%, rgba(30, 33, 40, 0.976021) 12.8%, rgba(30, 33, 40, 0) 100%);
}

.structure {
  width: 100%;
}

.container {
  min-height: 100vh;
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.devProgressFull{
  color: #fff
  }
  
  .devProgressMobile{
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    padding-left: 20px;
  }

.main {
  padding: 5rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer {
  width: 100%;
  height: 100px;
  border-top: 1px solid #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.title a {
  color: #0070f3;
  text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
  text-decoration: underline;
}

.title {
  margin: 0;
  line-height: 1.15;
  font-size: 4rem;
}

.title,
.description {
  text-align: center;
}

.description {
  line-height: 1.5;
  font-size: 1.5rem;
}

.code {
  background: #fafafa;
  border-radius: 5px;
  padding: 0.75rem;
  font-size: 1.1rem;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
}

.grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
  margin-top: 3rem;
}

.card {
  margin: 1rem;
  padding: 1.5rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
  width: 45%;
}

.card:hover,
.card:focus,
.card:active {
  color: #0070f3;
  border-color: #0070f3;
}

.card h2 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
}

.card p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
}

.logo {
  height: 1em;
  margin-left: 0.5rem;
}

@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}
