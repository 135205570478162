.card {
    background: #1e2128;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset -2px -2px 2px rgba(0, 0, 0, 0.15),
    inset 2px 2px 2px rgba(255, 255, 255, 0.07);
    border-radius: 10px;
    cursor: pointer;
    min-height: 400px;
}

.card:hover {
    box-shadow: inset 0px 6px 5px rgba(0, 0, 0, 0.1),
    inset 0px -1px 2px rgba(255, 255, 255, 0.07),
    inset 0px 2px 15px rgba(0, 0, 0, 0.3);
}

.primaryColor {
    color: #c0222c;
}

.button {
    position: absolute;
    bottom: 15px;
    right: 20px;
}

@media only screen and (min-width: 1024px) {
    .section3 {
        min-height: 20vh;
    }
}
