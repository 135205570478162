.primaryColor {
  color: #c0222c;
  font-weight: bold;
}
.downloadBtn {
  background-color: #c0222c;
}
.link {
  text-decoration: none;
  color: #c0222c;
  text-align: center;
}

.wrapper {
  z-index: 50;
  background: #1e2128;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%), inset -2px -2px 2px rgb(0 0 0 / 15%), inset 2px 2px 2px rgb(255 255 255 / 7%);
  border-radius: 5px;
  max-width: 100%;
  width: 700px;
  margin: auto;
}

.useCase{
  background-color: #c0222c;
}

@media only screen and (min-width: 1024px) {
  .section2 {
    min-height: 20vh;
    background: #1e2128;
  }
}
