.header {
    background-size: 100%;
    position: relative;
}

.portal {
    /* background: #131418; */
    /* Inner */

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset -2px -2px 2px rgba(0, 0, 0, 0.15),
    inset 2px 2px 2px rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    cursor: pointer;
}

.portal:hover {
    /* background: #131418; */
    box-shadow: inset 0px 6px 5px rgba(0, 0, 0, 0.1),
    inset 0px -1px 2px rgba(255, 255, 255, 0.07),
    inset 0px 2px 15px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}

.portalMain {
    background: #C0222C;
    /* Inner */

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset -2px -2px 2px rgba(0, 0, 0, 0.15),
    inset 2px 2px 2px rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    cursor: pointer;
}

.portalMain:hover {
    /* background: #131418; */
    box-shadow: inset 0px 6px 5px rgba(0, 0, 0, 0.1),
    inset 0px -1px 2px rgba(255, 255, 255, 0.07),
    inset 0px 2px 15px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}

.docs {
    font-family: Mulish;
    font-style: normal;
    letter-spacing: 0.02em;
    cursor: pointer;
}

.container {
    display: flex;
    justify-content: center;
}

.glowingStar {
    position: absolute;
    bottom: 5%;
    left: 20%;
    pointer-events: none
}

.glowingSmallStar {
    position: absolute;
    top: 20%;
    right: 12%;
    pointer-events: none
}
